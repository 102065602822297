export default data => {
	return new Promise((resolve, reject) => {
		// 将文件流数据转换为Blob对象
		const fileBlob = new Blob([data]);

		// 使用FileReader读取文件内容
		const reader = new FileReader();
		reader.onload = function () {
			const fileContent = reader.result; // 文件内容
			resolve(fileContent);
		};
		reader.readAsText(fileBlob);
	});
};
