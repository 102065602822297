import * as XLSX from "xlsx/xlsx.mjs";
// import { utils, writeFile } from "xlsx/xlsx.mjs";
function json2Excel(name, data) {
	const worksheet = XLSX.utils.json_to_sheet(data);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, "code");
	// XLSX.utils.book_append_sheet(workbook, worksheet, data);
	XLSX.writeFile(workbook, name);
}
export default json2Excel;
