import puppeteer from "puppeteer-web";
import json2Excel from "./exportExcel.js";
import axios from "axios";
import Socket from "./socket.js";
import readTxt from "./readTxt.js";
import readXlsx from "./readXlsx.js";
import encrypt from "./encrypt.js"; // 加密
import decrypt from "./decrypt.js";
const { CDP } = require("./CDP.js");
/**
 * @_context 当前连接的浏览器
 * @_url chrome devtools协议调试接口地址
 */

class RunTask {
	_url = "";

	_websocket_port = 43500; // 43500 - 43509

	_ws = null;

	_context = {};

	_sessionId = "";

	_break = false;

	_otherVariables = {
		task_id: "c1",
		task_name: "c2",
		serial_number: "c3",
	}; // 变量 - 其他

	_elementObject = {}; // 变量 - 元素对象

	_objectVariables = {}; // 类型为对象或数组的变量 -> 字段提取、随机提取、For循环数据

	_curContentIndex = 0;

	constructor(url, task_list) {
		this._url = url;
		this.task_list = task_list;
	}

	async newPage(config) {
		this._context.page = await this._context.browser.newPage();
	}

	async closePage(config) {
		await this._context.page.close();
	}

	async closeOtherPage() {
		const url = await this._context.page.url();
		const { targetInfos: targets } = await this._context.client.Target.getTargets();
		const otherPages = targets.filter(item => item.type === "page" && !item.title.startsWith("DevTools") && item.url !== url);
		for (const target of otherPages) {
			await this._context.client.Target.closeTarget({
				targetId: target.targetId,
			});
		}
	}

	async switchPage(config) {
		let { type, relation, content } = config;
		const { targetInfos: targets } = await this._context.client.Target.getTargets();
		const otherPages = targets.filter(item => item.type === "page" && !item.title.startsWith("DevTools"));
		const res = content.replace(/\${(.*?)}/g, (match, key) => this._otherVariables[key]);
		let target;
		switch (relation) {
			case "equal":
				target = otherPages.find(item => item[type] === res);
				break;
			case "notEqual":
				target = otherPages.find(item => item[type] !== res);
				break;
			case "contain":
				target = otherPages.find(item => item[type].includes(res));
				break;
			case "notContain":
				target = otherPages.find(item => !item[type].includes(res));
				break;
			default:
				break;
		}
		if (target) {
			await this._context.client.Target.activateTarget({
				targetId: target.targetId,
			});
		} else {
			throw new Error("未找到符合条件的标签页");
		}
	}

	async gotoUrl(config) {
		let { url } = config;
		let res = url.replace(/\${(.*?)}/g, (match, key) => this._otherVariables[key]);
		if (!this._context.page) {
			this._context.page = await this._context.browser.newPage();
		}
		if (!res.startsWith("http")) {
			res = `http://${res}`;
		}
		await this._context.page.goto(res);
	}

	async refreshPage(config) {
		const { timeout } = config;
		try {
			await this._context.page.reload({ timeout });
		} catch (error) {
			throw new Error("刷新页面 - 超时");
		}
	}

	async goBack(config) {
		const { timeout } = config;
		try {
			await this._context.page.goBack({ timeout });
		} catch (error) {
			throw new Error("页面后退 - 超时");
		}
	}

	async screenshotPage(config) {
		const { name, path, fullPage, format, quality } = config;
		try {
			const fileName = name ? `${name}.${format}` : `${this._otherVariables.task_id}${this._otherVariables.user_id}${new Date().valueOf()}.${format}`;

			const params = {
				fullPage: !!+fullPage,
				quality,
				type: format,
			};
			if (format === "png") delete params.quality;
			let unit8Array = await this._context.page.screenshot(params);
			const blob = new Blob([unit8Array], {
				type: "application/octet-stream",
			});
			const downloadUrl = URL.createObjectURL(blob);
			const downloadLink = document.createElement("a");
			downloadLink.href = downloadUrl;
			downloadLink.download = fileName;
			downloadLink.click();
			URL.revokeObjectURL(downloadUrl);
		} catch (error) {
			throw new Error("页面截图 - 发生错误");
		}
	}

	async passingElement(config) {
		let targetElement = await this.getTargetElementPTR(config);
		if (!targetElement) throw new Error("经过元素 - 未找到匹配元素");
		await targetElement.hover();
	}

	async selectElement(config) {
		const { value } = config;
		let targetElement = await this.getTargetElementPTR(config);
		let res_value = value.replace(/\${(.*?)}/g, (match, key) => this._otherVariables[key]);
		if (!targetElement) throw new Error("下拉选择器 - 未找到匹配元素");
		await targetElement.select(res_value);
	}

	async focusElement(config) {
		let targetElement = await this.getTargetElementPTR(config);
		if (!targetElement) throw new Error("元素聚焦 - 未找到匹配元素");
		await targetElement.focus();
	}

	async click(config) {
		let targetElement = await this.getTargetElementPTR(config);
		if (!targetElement) throw new Error("点击元素 - 未找到匹配元素");
		await targetElement.click();
	}

	async inputContent(config) {
		let targetElement = await this.getTargetElementPTR(config);
		if (!targetElement) throw new Error("输入内容 - 未找到匹配元素");
		let content = config.randomContent.split("\r\n");
		let res_content = content.map(item => item.replace(/\${(.*?)}/g, (match, key) => this._otherVariables[key]));
		// 随机选取
		if (+config.isRandom) {
			let index = this.getRandomNum(0, res_content.length - 1);
			await targetElement.type(res_content[index], {
				delay: config.intervals,
			});
		} else {
			// 按顺序输入
			await targetElement.type(res_content[this._curContentIndex], {
				delay: config.intervals,
			});
			this.changeContentIndex(res_content.length - 1);
		}
	}

	async scrollPage(config) {
		await this._context.page.evaluate(config => {
			const { scrollType, type, position, distance } = config;
			let top = 0;
			if (scrollType === "position") {
				switch (position) {
					case "top":
						top = 0;
						break;
					case "middle":
						top = document.body.scrollHeight / 2 - window.innerHeight / 2;
						break;
					case "bottom":
						top = document.body.scrollHeight;
						break;
					default:
						top = 0;
				}
			} else {
				top = distance;
			}
			window.scroll({
				top: top,
				behavior: config.type === "smooth" ? "smooth" : "auto",
			});
		}, config);
	}

	async uploadAttachment(config) {
		const { url: fileUrl } = config;
		const res_fileUrl = fileUrl.replace(/\${(.*?)}/g, (match, key) => this._otherVariables[key]);
		// 获取目标标签页  targetId;
		const url = await this._context.page.url();
		const { targetInfos: targets } = await this._context.client.Target.getTargets({
			filter: [{ type: "page", exclude: false }],
		});
		const target = targets.find(target => target.url === url);
		// 更新下sessionId
		await this._context.client.Target.attachToTarget({
			targetId: target.targetId,
			flatten: true,
		});
		const objectId = await this.getTargetElementCDP(config);
		if (!objectId) throw new Error("上传附件 - 未找到匹配元素");
		const { node } = await this._context.client.DOM.describeNode({ objectId }, this._sessionId);
		await this._context.client.DOM.setFileInputFiles(
			{
				objectId,
				files: [res_fileUrl],
				backendNodeId: node.backendNodeId,
			},
			this._sessionId
		);
	}

	async javaScript(config) {
		const { params, content, variable } = config;
		try {
			let result = await this._context.page.evaluate(config => {
				return eval(config.content);
			}, config);
			if (variable) {
				this._otherVariables[variable] = result;
			}
		} catch (error) {
			throw new Error("执行JS脚本 - 执行错误");
		}
	}

	async mouseClick(config) {
		const { button, type, x, y } = config;
		await this._context.page.mouse.move(x, y);
		await this._context.page.mouse.down({
			button,
			clickCount: type === "click" ? 1 : 2,
		});
	}

	async mouseMove(config) {
		const { x, y } = config;
		await this._context.page.mouse.move(x, y);
	}

	// keyboard.down 按下不释放，需要结合keyboard.up
	// keyboard.press 按下后释放
	async keyboard(config) {
		const { type } = config;
		await this._context.page.keyboard.press(type);
	}

	async keyCombination(config) {
		const { command } = config;
		const keys = command.split("+");
		await this._context.page.keyboard.down(keys[0]);
		await this._context.page.keyboard.down(keys[1]);
		await this._context.page.keyboard.up(keys[1]);
		await this._context.page.keyboard.up(keys[0]);
	}

	async waitTime(config) {
		const { timeoutType, timeout, timeoutMin, timeoutMax } = config;
		if (timeoutType === "fixedValue") {
			await this.sleep(timeout);
		} else {
			await this.sleep(this.getRandomNum(timeoutMin, timeoutMax));
		}
	}

	async waitForSelector(config) {
		const { selector, serial, isShow, timeout } = config;
		try {
			await this._context.page.waitForSelector(`${selector}:nth-child(${serial})`, { visible: isShow, timeout });
		} catch (error) {
			throw new Error(`等待元素出现 - 超时`);
		}
	}

	async waitForResponse(config) {
		const { url, timeout } = config;
		try {
			await this._context.page.waitForResponse(res => res.url().includes("proxy4free.com/web_v1"), { timeout });
		} catch (err) {
			throw new Error(`等待请求完成 - 超时`);
		}
	}

	async getUrl(config) {
		const { type, key, variable } = config;
		let url = await this._context.page.url();
		let res;
		switch (type) {
			case "href":
				res = url;
				break;
			case "origin":
				let urls = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/im);
				res = urls[0];
				break;
			case "search":
				const params = {};
				const params_str = url.split("?")[1];
				if (!params_str) throw new Error("获取URL - url中不存在参数");
				const params_arr = params_str.split("&");
				for (const item of params_arr) {
					params[item.split("=")[0]] = item.split("=")[1];
				}
				if (!params.hasOwnProperty(key)) throw new Error(`获取URL - url中不存在参数 ${key}`);
		}
		if (variable) {
			this._otherVariables[variable] = res;
		}
	}

	async getElement(config) {
		const { type, key, variable } = config;
		const targetElement = await this.getTargetElementPTR(config);
		if (!targetElement) throw new Error("元素数据 - 未找到匹配元素");
		let res;
		switch (type) {
			case "innerText":
				res = await targetElement.$$eval("*", el => el.map(item => item.innerText));
				break;
			case "object":
				res = targetElement;
				break;
			case "contentFrame":
				res = await targetElement.contentFrame();
				break;
			case "innerHTML":
				res = await this._context.page.evaluate(el => el.innerHTML, targetElement);
				break;
			case "attribute":
				res = await this._context.page.evaluate(config => {
					let target = document.querySelector(config.selector);
					return target.getAttribute(config.key);
				}, config);
				break;
			case "childrenNode":
				res = await targetElement.$$("*");
				break;
		}
		if (variable) {
			this._otherVariables[variable] = res;
		}
	}

	async getActiveElement(config) {
		const { variable } = config;
		const focusedElement = await this._context.page.evaluate(() => {
			return document.activeElement;
		});
		if (variable) {
			this._elementObject[variable] = focusedElement;
		}
	}

	async saveData(config) {
		const { name, template } = config;
		const res_template = template.replace(/\${(.*?)}/g, (match, key) => this._otherVariables[key]);
		// 将文本内容转换为Blob对象
		const blob = new Blob([res_template], { type: "text/plain" });

		// 生成Data URL
		const reader = new FileReader();
		reader.readAsDataURL(blob);

		reader.onload = () => {
			const dataUrl = reader.result;
			const a = document.createElement("a");
			a.href = dataUrl;
			a.download = `${name}.txt`;
			a.click();
		};
	}

	async exportExcel(config) {
		const { name, fields } = config;
		const all = {
			...this._otherVariables,
			...this._elementObject,
			...this._objectVariables,
		};
		const obj = {};
		fields.forEach(item => {
			obj[item] = all[item];
		});
		json2Excel(`${name}.xlsx`, [obj]);
	}

	// ⚠️
	async downloadFile(config) {
		const res = await this.send({
			rpa_task: "测试任务1",
			rpa_origin_json: {
				type: "downloadFile",
				config: {
					url: "https://ip.cc/sample-100-random-ips.original.csv",
					path: "D://Download//file",
					remark: "",
				},
			},
			rpa_task_id: "alksjflasjdflk123",
			rpa_task_name: "我的任务",
			env_id: "12330",
			is_timetask: false,
		});
		const res1 = await this.send({
			id: 49,
			name: "详情测试9",
			group_id: 19,
			exception: "continue",
			config: {
				remark: "9",
				timeout: 9,
				params: "9",
			},
			content: {
				type: "downloadFile",
				config: {
					url: "https://ip.cc/sample-100-random-ips.original.csv",
					path: "D://Download//file",
					remark: "",
				},
			},
			finish: ["closetabs", "closebrowser"],
			remark: "9",
		});
		const result = decrypt(res);
		if (!result.is_success) throw new Error("下载文件 - 下载失败");
	}

	// ⚠️
	async useExcel(config) {
		const data = await this.send({
			rpa_task: "测试任务1",
			rpa_origin_json: {
				type: "useExcel",
				config: {
					path: "D:/Download/file/CountryCode.xlsx",
					variableList: [],
					variable: "",
					remark: "",
				},
			},
			rpa_task_id: "alksjflasjdflk123",
			rpa_task_name: "我的任务",
			env_id: "12330",
			is_timetask: false,
		});
		if (Object.prototype.toString.call(data) !== "[object Blob]") {
			const res = decrypt(data);
			throw new Error(`导入Excel素材 - ${res.error_message}`);
		}
		const json = await readXlsx(data);
		console.log(json);
	}

	// ⚠️
	async importText(config) {
		const data = await this.send({
			rpa_task: "测试任务1",
			rpa_origin_json: {
				type: "importText",
				config: {
					path: "D:/Download/file/测试文件2.txt",
					variable: "a",
					remark: "",
				},
			},
			rpa_task_id: "alksjflasjdflk123",
			rpa_task_name: "我的任务",
			env_id: "12330",
			is_timetask: false,
		});
		// [object Blob]
		if (Object.prototype.toString.call(data) !== "[object Blob]") {
			const res = decrypt(data);
			throw new Error(`导入txt - ${res.error_message}`);
		}
		const text = await readTxt(data);
		console.log(text);
	}

	async getRequest(config) {
		const { url, type, key, variable } = config;
		const req = await this._context.page.waitForRequest(request => request.url().includes(url));
		if (!req) throw new Error("监听请求触发 - 未匹配到符合条件的请求");
		let result;
		switch (type) {
			case "url":
				result = req.url();
				break;
			case "headers":
				const headers = req.headers();
				if (headers[key]) throw new Error(`监听请求触发 - 请求头中不包含 ${key}`);
				result = headers[key];
				break;
			case "getParams":
				const params = {};
				const params_str = url.split("?")[1];
				if (!params_str) throw new Error("监听请求触发 - url中不存在Get参数");
				const params_arr = params_str.split("&");
				for (const item of params_arr) {
					params[item.split("=")[0]] = item.split("=")[1];
				}
				if (!params.hasOwnProperty(key)) throw new Error(`获取URL - url中不存在参数 ${key}`);
				result = params[key];
				break;
			case "postData":
				const postData = req.postData();
				result = postData;
				break;
			default:
				break;
		}
		if (variable) this._otherVariables[variable] = result;
	}

	async getResponse(config) {
		const { url, variable } = config;
		const response = await this._context.page.waitForResponse(res => res.url().includes(url) && res._request._method != "OPTIONS");
		if (!response) throw new Error("监听请求结果 - 未匹配到符合条件的请求");
		const responseData = await response.text();
		if (variable) this._otherVariables[variable] = responseData;
	}

	async stopLinsten(config) {}

	async extractData(config) {
		const { content, reg, variable, notUpper } = config;
		const allVariables = {
			...this._otherVariables,
			...this._elementObject,
			...this._objectVariables,
		};
		const text = allVariables[content];
		const regexp = new RegExp(reg, notUpper == 0 ? "g" : "gi");
		const res = text.match(regexp);
		if (res) {
			this._otherVariables[variable] = res[0];
		} else {
			throw new Error("文本中提取 - 未匹配到字符");
		}
	}

	async toJson(config) {
		const { content, variable } = config;
		try {
			const allVariables = {
				...this._otherVariables,
				...this._elementObject,
				...this._objectVariables,
			};
			const text = allVariables[content];
			const json = JSON.parse(text);
			this._objectVariables[variable] = json;
		} catch (error) {
			throw new Error("转换JSON对象 - 失败");
		}
	}

	async extractKey(config) {
		const { content, key, variable } = config;
		const value = this._objectVariables[content][key];
		if (value) this._otherVariables[variable] = value;
		else throw new Error("提取Key - 未匹配到Key");
	}

	async randomGet(config) {
		const { content, variable } = config;
		const keys = Object.keys(this._objectVariables[content]);
		const randomIndex = this.getRandomNum(0, keys.length - 1);
		const key = keys[randomIndex];
		const value = this._objectVariables[content][key];
		if (value) this._otherVariables[variable] = value;
		else throw new Error("随机提取 - 未匹配到Key");
	}

	async ifElse(config) {
		const { condition, relation, result, children, other } = config;
		const allVariables = {
			...this._otherVariables,
			...this._elementObject,
			...this._objectVariables,
		};
		const content = condition.replace(/\${(.*?)}/g, (match, key) => allVariables[key]);

		let res;
		if (result.startsWith("$")) {
			const key_result = result.match(/\$\{(.*?)\}/)[1];
			res = allVariables[key_result];
		} else {
			res = result;
		}
		let flag = false;
		switch (relation) {
			case "exist":
				flag = !!content;
				break;
			case "notExist":
				flag = !content;
				break;
			case "less":
				flag = content < res;
				break;
			case "lessEqual":
				flag = content <= res;
				break;
			case "equal":
				flag = content == res;
				break;
			case "notEqual":
				flag = content != res;
				break;
			case "more":
				flag = content > res;
				break;
			case "moreEqual":
				flag = content >= res;
				break;
			case "contain":
			case "oneOf":
				flag = content.includes(res);
				break;
			case "notContain":
			case "notOneOf":
				flag = !content.includes(res);
			default:
				break;
		}
		console.log({ res, content });
		console.log(flag);
		if (flag) {
			for (let item of children) {
				await this[item.type](item.config);
			}
		}
		if (!flag && config.hasOwnProperty("other") && Array.isArray(config.other)) {
			for (let item of other) {
				await this[item.type](item.config);
			}
		}
	}

	async forElements(config) {
		const { selector, type, key, variableIndex, variable, children } = config;
		this._break = false;
		// 尝试获取目标元素
		let elements = await this._context.page.$$(selector);
		if (!elements)
			elements = await this._context.page.waitForSelector(selector, {
				visible: true,
				timeout: 30000,
			});
		if (!elements) throw new Error("For循环元素 - 未匹配到指定元素");
		// 提取
		for (let i = 0; i < elements.length; i++) {
			if (this._break) break;
			const el = elements[i];
			let value;
			switch (type) {
				case "innerText":
					value = await this._context.page.evaluate(el => el.innerText, el);
					break;
				case "object":
					value = el;
					break;
				case "contentFrame":
					value = await el.contentFrame();
					break;
				case "innerHTML":
					value = await this._context.page.evaluate(el => el.innerHTML, el);
					break;
				case "attribute":
					value = await this._context.page.evaluate((el, key) => el.getAttribute(key), el, key);
					break;
				case "childrenNode":
					value = await this._context.page.evaluate(el => {
						return Array.from(el.childNodes).map(node => {
							return {
								tagName: node.tagName,
								textContent: node.textContent,
							};
						});
					}, el);
					break;
				default:
					break;
			}
			this._elementObject[variable] = value;
			this._elementObject[variableIndex] = i;
			for (const item of children) {
				await this[item.type](item.config);
			}
			console.log(this._elementObject[variable]);
			console.log(this._elementObject[variableIndex]);
		}
	}

	async forTimes(config) {
		const { variableIndex, times, children } = config;
		this._break = false;
		for (let i = 0; i < times; i++) {
			if (this._break) break;
			this._objectVariables[variableIndex] = i;
			for (const item of children) {
				await this[item.type](item.config);
			}
		}
	}

	async forLists(config) {
		const { content, variableIndex, variable, children } = config;
		this._break = false;
		const list = this._objectVariables[content];
		if (Array.isArray(list)) {
			for (const item of children) {
				if (this._break) break;
				await this[item.type](item.config);
			}
		} else {
			throw new Error(`For循环数据 - 无法循环数据 ${content}`);
		}
	}

	async breakLoop(config) {
		this._break = true;
	}

	async closeBrowser(config) {
		await this._context.browser.close();
	}

	// Puppeteer 获取目标元素
	async getTargetElementPTR(config) {
		const { selectorType, selector, element, serialType, serial, serialMin, serialMax, value } = config;
		let targetElement;
		if (selectorType === "selector") {
			let els = await this._context.page.$$(selector);
			if (serialType === "fixedValue") {
				targetElement = els[serial - 1];
			} else {
				targetElement = els[this.getRandomNum(serialMin, serialMax) - 1];
			}
		} else {
			targetElement = this._elementObject[element];
		}
		return targetElement;
	}

	// CDP 获取目标元素
	async getTargetElementCDP(config) {
		const { selector, selectorType, element, serialType, serial, serialMin, serialMax } = config;
		try {
			const { result: documentElementObject } = await this._context.client.Runtime.evaluate(
				{
					expression: "document",
					returnByValue: false,
					awaitPromise: true,
					userGesture: true,
				},
				this._sessionId
			);
			const { result: targetElementObject } = await this._context.client.Runtime.callFunctionOn(
				{
					functionDeclaration: "(element, selector) => element.querySelector(selector)",
					objectId: documentElementObject.objectId,
					arguments: [
						{
							objectId: documentElementObject.objectId,
						},
						{
							value:
								serialType === "fixedValue"
									? `${selector}:nth-child(${serial})`
									: `${selector}:nth-child(${this.getRandomNum(serialMin, serialMax)})`,
						},
					],
					returnByValue: false,
					awaitPromise: true,
					userGesture: true,
				},
				this._sessionId
			);
			if (targetElementObject.subtype === "null") {
				throw new Error("The target element does not exist!");
			}
			return targetElementObject.objectId;
		} catch (error) {}
	}

	// 改变当前输入内容索引
	changeContentIndex(max) {
		if (this._curContentIndex >= max) this._curContentIndex = 0;
		else this._curContentIndex++;
	}

	getRandomNum(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	sleep(time) {
		return new Promise(r => setTimeout(r, time));
	}

	async connectWebsocket(port) {
		return new Promise((resolve, reject) => {
			this._ws = new WebSocket(`ws://127.0.0.1:${port}`);
			// this._ws.onmessage = this.onmessage.bind(this);
			this._ws.onopen = () => {
				setTimeout(() => {
					resolve();
				}, 1000);
			};
			this._ws.onerror = () => {
				setTimeout(() => {
					reject();
				}, 1000);
			};
		});
	}

	async initWebsocket() {
		return new Promise(async (resolve, reject) => {
			for (let port = 43500; port < 43509; port++) {
				if (this._ws && this._ws.readyState == 1) break;
				await this.connectWebsocket(port)
					.then(() => {
						resolve();
					})
					.catch(err => {});
			}
		});
	}

	async debug(data) {
		const pages = await this._context.browser.pages();
		console.log(pages);
	}

	send(data) {
		return new Promise((resolve, reject) => {
			this._ws.send(encrypt(JSON.stringify(data)));
			this._ws.onmessage = e => {
				resolve(e.data);
			};
		});
	}

	async run() {
		// 初始化websocket
		if (!this._ws || this._ws.readyState != 1) {
			await this.initWebsocket();
		}
		// 连接 devtools
		this._context.browser = await puppeteer.connect({
			browserWSEndpoint: this._url,
			defaultViewport: null,
		});
		// 当puppeteer无法使用时，使用原生CDP交互
		const options = {
			target: this._url,
			local: true,
		};
		this._context.client = await CDP(options);
		this._context.client.on("event", message => {
			this._sessionId = message.params.sessionId;
		});
		// 执行任务
		for (let item of this.task_list) {
			try {
				await this[item.type](item.config, item);
			} catch (error) {
				console.error(error.message);
			}
		}
		await this.debug();
	}
}

export default RunTask;
