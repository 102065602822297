import * as XLSX from "xlsx/xlsx.mjs";
export default data => {
	return new Promise((resolve, reject) => {
		const fileData = new Blob([data]);
		const fileReader = new FileReader();
		fileReader.onload = function () {
			const arrayBuffer = fileReader.result;
			const workbook = XLSX.read(arrayBuffer, { type: "arraybuffer" });
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
			const keys = jsonData.shift();
			const json = jsonData.map((item, index) => {
				let obj = {};
				for (let i = 0; i < keys.length; i++) {
					obj[keys[i]] = item[i];
				}
				return obj;
			});
			resolve(json);
		};
		fileReader.readAsArrayBuffer(fileData);
	});
};
